import BannerHeader from "pages/common/BannerHeader"
import WhyProduct from "../Common/WhyProduct"
import GetStart from "pages/common/GetStart"
import ContentWrapper from "pages/common/ContentWrapper"
import new1 from "images/Product/Performance/new-1.png"
import new2 from "images/Product/Performance/new2.png"
import new3 from "images/Product/Performance/new3.png"
import { StaticImage } from "gatsby-plugin-image"
import { useFormatter } from "helpers/i18n"
import bannerMobile from "images/Product/Performance/banner_1_mobile.png"

export default function Performance() {
  const { __ } = useFormatter()
  const banner = "../../../images/Product/Performance/banner-1.png"

  return (
    <>
      <BannerHeader
        breadcrumb={[
          {
            label: `${__({ defaultMessage: "Home/" })}`,
            url: "/",
          },
          {
            label: `${__({ defaultMessage: "Products/" })}`,
          },
          {
            label: `${__({
              defaultMessage: "Performance Dashboard",
            })}`,
          },
        ]}
        banner={
          <StaticImage
            layout="fullWidth"
            src={banner}
            alt=""
            decoding="async"
            fetchpriority="high"
            quality={100}
          />
        }
        mobileBanner={<img src={bannerMobile} alt="" />}
        logo={""}
        listTitle={[
          `${__({
            defaultMessage: "Performance Dashboard",
          })}`,
        ]}
        description={`${__({
          defaultMessage:
            "Measure and magnify your impact with our powerful performance dashboard.",
        })}`}
      />
      <ContentWrapper customClass="margin-100-pc">
        {[
          <WhyProduct
            title={`${__({
              defaultMessage: "Shopper Segmentation",
            })}`}
            description={`${__({
              defaultMessage:
                " Segment Shoppers (new/returning/lapsed/unique shopper) in each sub-category across different platforms.",
            })}`}
            image={new1}
          />,
          <ContentWrapper customClass="margin-200-pc">
            <WhyProduct
              title={`${__({
                defaultMessage: "Best Performing Products and Categories",
              })}`}
              description={`${__({
                defaultMessage:
                  "Include top categories, top subcategories, and top assortments.",
              })}`}
              image={new2}
            />
          </ContentWrapper>,
          <ContentWrapper customClass="margin-200-pc">
            <WhyProduct
              title={`${__({
                defaultMessage: "Traffic and Conversion Rate",
              })}`}
              description={`${__({
                defaultMessage:
                  "We provide visibility in terms of sales, shoppers, inventory, traffic, and conversion rate.",
              })}`}
              image={new3}
            />
          </ContentWrapper>,
        ].map((component, key) => (
          <section key={key}>
            <div className="reveal">{component}</div>
          </section>
        ))}
      </ContentWrapper>
      <div style={{ marginTop: 87 }}></div>
      <GetStart
        productName={`${__({ defaultMessage: "Performance Dashboard" })}`}
      />
    </>
  )
}
