import { useState } from "react"
import Alert from "react-bootstrap/Alert"
import { validateAndSendEmail } from "helpers/feedback"
import "./GetStart.scss"
import { useFormatter } from "helpers/i18n"

export default function GetStart({ productName, className }) {
  const { __ } = useFormatter()
  const [email, setEmail] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [sentSuccessMessage, setSentSuccessMessage] = useState()
  const [sentErrorMessage, setSentErrorMessage] = useState()
  const emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"

  const dismissAlert = () => {
    const timeId = setTimeout(() => {
      setSentSuccessMessage()
      setSentErrorMessage()
      setIsLoading(false)
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }

  const onSubmit = e => {
    if (email && email.match(emailPattern)) {
      e.preventDefault()
      setIsLoading(true)
      const params = {
        subject: productName,
        email: email,
        type: "subscription_type",
      }
      validateAndSendEmail(params).then(result => {
        if (result.success) {
          setSentSuccessMessage(result.message)
          setEmail("")
        } else {
          setSentErrorMessage(result.message)
        }
        dismissAlert()
      })
    }
  }

  const renderResponseSentFeedback = () => {
    if (sentSuccessMessage) {
      return (
        <div className="NbeYsAAaEr">
          <Alert className="custom-success">{sentSuccessMessage}</Alert>
        </div>
      )
    }
    if (sentErrorMessage) {
      return (
        <div className="NbeYsAAaEr">
          <Alert className="custom-error">{sentErrorMessage}</Alert>
        </div>
      )
    }
    return (
      <form className="NbeYsAAaEr" id="ContactUs">
        <div className="rUlXatKJNp">
          <input
            className="GITXtTeida"
            required={true}
            value={email}
            type="email"
            pattern={emailPattern}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email*"
            disabled={isLoading}
          />
        </div>
        <div className="JHwNzWGKOk">
          <button
            type="submit"
            disabled={isLoading}
            className="jXqJuKIYqQ"
            onClick={onSubmit}
          >
            {__({ defaultMessage: "Send" })}
          </button>
        </div>
      </form>
    )
  }

  return (
    <>
      <div className={`AJFrkzepye ${className}`}>
        <div className="vApfFyhUhD">
          <div className="section-title khBEGSyJhn">
            {__({ defaultMessage: "Get started!" })}
          </div>
          <div className="iaLbXaKKIl">
            {__({ defaultMessage: "Join OnPoint to Thrive Online Now!" })}
          </div>
        </div>
        {renderResponseSentFeedback()}
      </div>
    </>
  )
}
