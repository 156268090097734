import "./WhyProduct.scss"

export default function WhyProduct({
  title,
  description,
  image,
  iconHeader,
  imageMobile = null,
}) {
  return (
    <>
      <div className="RFsqBwFfDx">
        {iconHeader ? (
          <div className="tQABJqigjF">
            <img src={iconHeader} alt="" className="wmuFooXlAs" />
          </div>
        ) : null}
        <div className="gWVJYTPoZM">{title}</div>
        {description ? <div className="iEjXIcbLTO">{description}</div> : null}
        <div className="qoINoGVEgL">
          {imageMobile ? (
            <>
              <img alt="" className="hNllUaqzHK-mobile" src={imageMobile} />
              <img alt="" className="hNllUaqzHK hover01" src={image} />
            </>
          ) : (
            <img alt="" className="hNllUaqzHK-2 hover01" src={image} />
          )}
        </div>
      </div>
    </>
  )
}
